@import url('https://fonts.googleapis.com/css?family=Dancing+Script|Josefin+Slab|Pacifico');
@import url('https://fonts.googleapis.com/css?family=Butterfly+Kids');

h1 {
	color: white;
	text-align: center;
	/*font-family: "Butterfly Kids", cursive;*/
	font-family: "Playwrite AU NSW", cursive;
}

h3 {
	font-family: "Josefin Slab", serif;
	color: white;
	text-align: center;
	font-weight: 300;
}

#heart {
	width: 10px;
	position: absolute;
	left: 200px;
	top: 200px;

	text-align: center;

	background-color: "blue";
	width: 10px;
	height: 90px;
}
#heart:before,
#heart:after {
	position: absolute;
	background-color: "blue";
	content: "";
	left: 50px;
	top: 0;
	width: 50px;
	height: 80px;
	background: red;
	-moz-border-radius: 50px 50px 0 0;
	border-radius: 50px 50px 0 0;
	-webkit-transform: rotate(-45deg);
	-moz-transform: rotate(-45deg);
	-ms-transform: rotate(-45deg);
	-o-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-transform-origin: 0 100%;
	-moz-transform-origin: 0 100%;
	-ms-transform-origin: 0 100%;
	-o-transform-origin: 0 100%;
	transform-origin: 0 100%;

	background: #fff;
	-webkit-animation: random 5s infinite;
	animation: random 5s infinite;
}
@keyframes random {
	15% {
		background: red;
	}
	30% {
		background: pink;
	}
	45% {
		background: white;
	}
	60% {
		background: red;
	}
	75% {
		background: pink;
	}
}

#heart:after {
	background-color: "blue";
	left: 0;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	-webkit-transform-origin: 100% 100%;
	-moz-transform-origin: 100% 100%;
	-ms-transform-origin: 100% 100%;
	-o-transform-origin: 100% 100%;
	transform-origin: 100% 100%;
}
