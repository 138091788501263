.weddm-wrapper{
	--bs-gutter-x: 0 !important;
}

.weddm-image-box-content {
	
}

.weddm-image-box-title {
	margin-bottom: 5px;
	font-size: 25px;
	line-height: 1.3em;
	color: rgb(151, 98, 122) !important;
}

@media (max-width: 768px) {
	.weddm-image {
		width: 35%;
		height: 55%;
	}
}

.weddm-bg {
	background-image: url("https://projects.dika.web.id/assets/undangan/background.jpeg");
}

.img-mempelai {
	box-shadow: 5px 5px 10px grey;
}