@media (min-width: 768px) {
    .img-qris {
        width: 25% !important;
    }
}

@media (max-width: 768px) {
    .img-qris {
        width: 100% !important;
    }
}