.app {
	display: flex;
	width: 100vh;
	height: 100vh;
	align-items: center;
	justify-content: center;
}

body {
	margin: 0;
	background-color: #333;
}