.wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	flex-direction: column;
}
span {
	color: #eee;
}
button {
	cursor: pointer;
	position: absolute;
	width: fit-content;
	height: fit-content;
	transition: all 0.2s;
	border-radius: 8px;
}
#yes {
	transform: translate(-50px, 120px);
}
#no {
	transform: translate(80px, 120px);
}
