html {
	scroll-behavior: smooth !important;
}

body {
	font-family: 'Josefin Sans', sans-serif !important;
	padding: 0 !important;
	-ms-overflow-style: none !important;
	scrollbar-width: none !important;
}

body::-webkit-scrollbar {
	display: none !important;
}

.text-standard {
	color: rgb(103, 73, 63) !important;
}

.font-standard {
	font-family: 'Roboto', Sans-serif, sans-serif !important;
}

.font-esthetic {
	font-family: 'Sacramento', cursive !important;
	/*font-family: "Butterfly Kids", cursive !important;*/
}

.font-ubuntu {
	font-family: "Ubuntu Sans", sans-serif;
}

.font-arabic {
	font-family: 'Noto Naskh Arabic', serif !important;
}

.img-crop {
	width: 14rem;
	height: 14rem;
	position: relative;
	overflow: hidden;
	border-radius: 50%;
}

.img-crop>img {
	display: inline;
	margin: 0 auto;
	height: auto;
	width: 100%;
}

.btn-music {
	position: fixed;
	bottom: 9vh !important;
	right: 2vh !important;
	z-index: 1055 !important;
}

.card-body {
	overflow-wrap: break-word !important;
}

.mouse-animation {
	padding: 0.25rem 0.625rem;
	height: 2rem;
	border: 0.1rem solid rgb(103, 73, 63);
	border-radius: 1.4rem;
	opacity: 0.75;
	box-sizing: content-box;
}

@keyframes scroll {
	0% {
		opacity: 0;
	}

	10% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(1rem);
		opacity: 0;
	}
}

.mouse-animation>.scroll-animation {
	width: 0.25rem;
	height: 0.625rem;
	border-radius: 25%;
	background-color: #000000;
	animation-name: scroll;
	animation-duration: 2.5s;
	animation-timing-function: linear;
	animation-iteration-count: infinite;
}

.dark-section {
	background-color: #111111 !important;
	margin-top: -0.5rem;
	margin-bottom: -0.5rem;
}

.light-section {
	background-color: #F5F3ED !important;
	margin-top: -0.5rem;
	margin-bottom: -0.5rem;
	color: #5B5857 !important;
}

footer {
	background-color: #111111 !important;
	margin-top: -0.5rem;
	padding-bottom: 5rem;
}

.text-default {
	color: rgb(103, 73, 63) !important;
}

.wd-container {
	height: 100vh !important;
}

.nav-item .nav-link, .nav-item .nav-link span {
	color: rgb(216, 164, 141) !important;
}

.photo-main img {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover; /* Ensures the image covers the container */
	transition: transform 0.3s ease-in-out; /* Smooth transition for zoom effect */
}

.fixed-bottom {
	z-index: 1000 !important;
}