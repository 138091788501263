.fa-music {
    position: fixed;
    right: 20px;
    bottom: 70px;
    width: 40px;
    height: 40px;
    border: 1px solid transparent;
    z-index: 1;
    line-height: 38px !important;
    cursor: pointer;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0 0 2px #444444;
    text-shadow: 0 0 1px #444444;
    color: rgb(216, 164, 141) !important;
}

.fa, .fas {
    border-color: rgb(216, 164, 141) !important;
}

.fa-music.pause:after {
    background-color: rgb(216, 164, 141) !important;
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    transform: rotate(-36deg);
}