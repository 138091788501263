.loading-page {
	position: fixed;
	inset: 0 !important;
	width: 100%;
	height: 100%;
	z-index: 1056 !important;
	background: rgba(0, 0, 0, 0.5) url('https://projects.dika.web.id/assets/prewed/opening_small.jpg') no-repeat center;
	background-blend-mode: overlay; /* Experiment with different blend modes */
	background-size: cover;
	filter: grayscale(30%);
}

.wrapper-welcome {
	height: 100vh !important;
}

.wd-background-overlay {
	background-color: transparent;
	background-image: linear-gradient(180deg, #00000000 51%, #000000 100%);
	opacity: 1;
	transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

@keyframes bounce-in{
	0%    {opacity: 0;   transform: scale(0.5);}
	100%  {opacity: 1;   transform: scale(1);}
}
@keyframes bounce-out{
	0%    {opacity: 1;   transform: scale(1);}
	100%  {opacity: 0;   transform: scale(0.5);}
}