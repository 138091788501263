.wd-main {
	/*background-color: #212529;*/
	background-color: white;
	background-image: url("https://be.satu.love/pictures/picture_16698620798142ryszou.jpeg");
	/*background-repeat: repeat;*/
	/*background-position: center center;*/
	background-size: cover;
	background-position: -400px;
}

.wd-main .bunga-atas {
	box-sizing: border-box; 
	display: block; 
	overflow: hidden; 
	width: initial; 
	height: 50%; 
	background: none; 
	opacity: 1; 
	border: 0px; 
	margin: 0px;
	padding: 0px; 
	position: absolute; 
	inset: 0px;
}

.wd-main .bunga-atas img{
	inset: 0px;
	box-sizing: border-box; 
	padding: 0px; 
	border: none; 
	margin: auto; 
	width: 0px; 
	min-width: 40%; 
	max-width: 50%; 
	object-fit: contain;
	opacity: 0.8;
}

.wd-main .bunga-bawah {
	box-sizing: border-box; 
	display: block; 
	overflow: hidden; 
	width: initial; 
	height: initial; 
	background: none; 
	opacity: 1; 
	border: 0px; 
	margin: 0px; 
	padding: 0px; 
	position: absolute; 
	inset: 0px;
}

.wd-main .bunga-bawah img {
	position: absolute; 
	inset: 0px;
	box-sizing: border-box; 
	padding: 0px; 
	border: none; 
	width: 0px; 
	min-width: 100%; 
	max-width: 100%;  
	object-fit: contain;
	opacity: 0.4;
	margin-top: 60vh;
}